import React from 'react';
import "./index.style.scss"

export const samplePagesConfigs = [
  {
    path: '/sample/table',
    component: React.lazy(() => import('./Table')),
  },  {
    path: ['/sample/course', '/sample/course/:id'],
    component: React.lazy(() => import('./forms/Course')),
  }, {
    path: ['/sample/for-who', '/sample/for-who/:id'],
    component: React.lazy(() => import('./forms/ForWho')),
  },{
    path: ['/sample/learning-format', '/sample/learning-format/:id'],
    component: React.lazy(() => import('./forms/LearningFormat')),
  },{
    path: ['/sample/group-start', '/sample/group-start/:id'],
    component: React.lazy(() => import('./forms/GroupStart')),
  },{
    path: ['/sample/student-work', '/sample/student-work/:id'],
    component: React.lazy(() => import('./forms/StudentWork')),
  },{
    path: ['/sample/teacher', '/sample/teacher/:id'],
    component: React.lazy(() => import('./forms/Teacher')),
  },
];
 