import React from 'react';
import {BiAlignLeft} from 'react-icons/bi';
 
const routesConfig = [
  {
    id: 'app',
    title: 'Sample',
    messageId: 'sidebar.sample',
    type: 'group',
    children: [
      {
        id: 'course',
        title: 'Page 1',
        messageId: 'sidebar.sample.courses',
        type: 'item',
        icon: <BiAlignLeft />,
        path: '/sample/table?page=course',
      },
      {
        id: 'for-who',
        title: 'Page 1',
        messageId: 'sidebar.sample.for-who',
        type: 'item',
        icon: <BiAlignLeft />,
        path: '/sample/table?page=for-who',
      }, {
        id: 'learning-format',
        title: 'Page 1',
        messageId: 'sidebar.sample.learning-format',
        type: 'item',
        icon: <BiAlignLeft />,
        path: '/sample/table?page=learning-format',
      }, {
        id: 'group-start',
        title: 'Page 1',
        messageId: 'sidebar.sample.group-start',
        type: 'item',
        icon: <BiAlignLeft />,
        path: '/sample/table?page=group-start',
      }, {
        id: 'student-work',
        title: 'Page 1',
        messageId: 'sidebar.sample.student-work',
        type: 'item',
        icon: <BiAlignLeft />,
        path: '/sample/table?page=student-work',
      }, {
        id: 'teacher',
        title: 'Page 1',
        messageId: 'sidebar.sample.teacher',
        type: 'item',
        icon: <BiAlignLeft />,
        path: '/sample/table?page=teacher',
      },
      
    ],
  } 
  
];
export default routesConfig;
